import React from 'react';
import { graphql } from 'gatsby';

const IndexPage = () => (
  <div />
);

export default IndexPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        clientId,
        productId,
      }
    }
  }
`;
